


















import { Vue, Component } from "vue-property-decorator";
import landWorkerDashboardRoutes from '../../router/landWorkerDashboard';

@Component
export default class Index extends Vue {
  private routes = landWorkerDashboardRoutes;
}
